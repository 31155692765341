




































import {
  defineComponent, computed, onMounted,
} from '@nuxtjs/composition-api';
import {
  SfButton,
  SfList,
  SfBottomModal,
} from '@storefront-ui/vue';
import {
  useCurrency,
} from '~/composables';

const currencyMap = {
  AUD: '&#36',
  GBP: '&#163',
  CAD: '&#36',
  CNY: '&#165',
  EUR: '&#8364',
  INR: '&#8377',
  ILS: '&#8362',
  MXN: '&#36',
  NZD: '&#36',
  PHP: '&#8369',
  PLN: '&#122&#322',
  RON: '&#108&#101&#105',
  RUB: '&#8381',
  LKR: '&#8360',
  SEK: '&#107&#114',
  USD: '&#36',
  UAH: '&#8372',
};

export default defineComponent({
  name: 'CurrenciesModal',
  components: {
    SfButton,
    SfList,
    SfBottomModal,
  },
  props: {
    isModalOpen: Boolean,
    selectedCurrency: {
      type: String,
      default: '',
    },
  },
  emits: ['closeModal'],
  setup() {
    const {
      currency: currencies,
      change: changeCurrency,
      load: loadCurrencies,
    } = useCurrency();

    const availableCurrencies = computed<string[]>(() => currencies.value?.available_currency_codes || []);

    onMounted(() => {
      if (currencies.value && currencies.value?.available_currency_codes) { return; }
      loadCurrencies();
    });

    return {
      changeCurrency,
      availableCurrencies,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    currencySymbol(code) {
      return currencyMap?.[code] ?? '';
    }
  },
});
