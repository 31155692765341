

















import { SfButton } from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent
} from '@nuxtjs/composition-api';
import CurrenciesModal from './CurrencySelector/CurrenciesModal.vue';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';

export default defineComponent({
  name: 'CurrencySelector',
  components: {
    SfButton,
    CurrenciesModal
  },
  props: {
    buttonClass: {
      required: false,
      type: Object,
      default: () => ({ 'container__currency container__currency--selected': true })
    }
  },
  setup () {
    const {
      selectedCurrency,
      selectedLocale
    } = useMagentoConfiguration();

    const currentCurrencySymbol = computed(() => {
      try {
        return (0).toLocaleString(
          selectedLocale.value.replace(/[!"$-/:-?[\]^_`{-~]/, '-'),
          {
            style: 'currency',
            currency: selectedCurrency.value,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
          .replace(/\d/g, '')
          .trim();
      } catch {
        return selectedLocale.value;
      }
    });

    const isCurrencyModalOpen = ref(false);

    return {
      currentCurrencySymbol,
      isCurrencyModalOpen,
      selectedCurrency,
      selectedLocale
    };
  }
});
